import React from "react";
import { useState, useEffect } from "react";
import { Container } from 'reactstrap';
import DateInput from "../../common/DateInput";
import TextInput from "../../common/TextInput";
import SelectInput from "../../common/SelectInput";
import CloudinaryUploadWidget from "../../common/CloudinaryUploadWidget";
import useDidMountEffect from '../../../customHooks/useDidMountEffect';
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';

function UserProfileForm(props) {
	const cloudinarySettings = props.cloudinarySettings;
	const [bioTextLimit, setBioTextLimit] = useState(props.bioTextLimit);

	useDidMountEffect(() => {
		if (props.userProfile !== null && props.userProfile !== undefined && props.userProfile.bio !== null && props.userProfile.bio !== undefined) {
			setBioTextLimit(props.bioTextLimit - props.userProfile.bio.length);
		}

	}, [props.userProfile]);

	return (
		<Container>
			<form onSubmit={props.onSubmit}>
				<br />
			<div className="form-container">
				<h3>About you</h3>
					<p>Your profile image, bio and home (port) location helps your friends and other social users find you and follow your journey through Explore with Imray.</p>
					<br />
					<div className="form-input">
						{cloudinarySettings &&
						<div className="profile-img-preview">
							<div>
								<img src={props.userProfile.imageId ?
									// add ?timestamp to force re-rendering
									`https://res.cloudinary.com/${cloudinarySettings.cloud}/image/upload/w_128,c_fill,ar_1:1,g_auto,r_max,b_rgb:ffffff/${props.userProfile.imageId}?${(new Date()).getTime()}` : '/img/Explore-w-Imray-user-image-default@2x.jpg'} />
								<div>
									<h5>{props.userProfile.firstName} {props.userProfile.lastName}</h5>
									<span>{[props.userProfile.boatName, props.userProfile.currentPort].filter(Boolean).join(", ")}</span>
								</div>
							</div>
							<CloudinaryUploadWidget
								caption="Edit profile image"
								cloudName={cloudinarySettings.cloud}
								uploadPreset={cloudinarySettings.userProfileUploadPreset}
								api_key={cloudinarySettings.apiKey}
								public_id={props.userProfile.imageId?.match(/\/([^/]+?)(\?|$)/)[1]} // last section of the path w/o params
								onImageUpload={props.onImageUpload}
								multiple={false}
							/>
						</div>
						}
					</div>
					<br />
					<br />
				{/* user name and bio */}
				<div className="two-column-row">
				<div className="form-input column">
					<TextInput
						id="firstName"
						label="First name*"
						onChange={props.onTextChange}
						name="firstName"
						value={props.userProfile.firstName || ""}
						error={props.errors.firstName}
					/>
				</div>
				<div className="form-input column">
					<TextInput
						id="lastName"
						label="Last name*"
						onChange={props.onTextChange}
						name="lastName"
						value={props.userProfile.lastName || ""}
						error={props.errors.lastName}
					/>
					</div>
				</div>
				<div className="two-column-row">
				<div className="form-input column">
					<TextInput
						id="bio"
						label="Bio"
						onChange={props.onTextChange}
						name="bio"
						value={props.userProfile.bio || ""}
						error={props.errors.bio}
					/>

					<span className="input-character-limit">{bioTextLimit} characters remaining</span>
				</div>
				<div className="form-input column">
					<TextInput
						id="currentPort"
						label="Home (port) Location"
						onChange={props.onTextChange}
						name="currentPort"
						value={props.userProfile.currentPort || ""}
						error={props.errors.currentPort}
					/>
					</div>
					</div>
					<br />
					<p className="small">* Indicates a required field. Using an image of yourself along with your real first and last name is optional.</p>
					<div>
					</div>
					<br />
					<hr></hr>
					<br />
				<h3>About your boat</h3>
					<p>Your boat name, model and other key characteristics help to enhance the Explore with Imray social experience for you and other users.</p>
					<br />
				{/* optional boat info*/}
				<div className="two-column-row">
				<div className="form-input column">
					<TextInput
						id="boatName"
						label="Boat name"
						onChange={props.onTextChange}
						name="boatName"
						value={props.userProfile.boatName || ""}
						error={props.errors.boatName}
					/>
				</div>
				<div className="form-input column">
					<TextInput
						id="boatType"
						label="Boat type"
						onChange={props.onTextChange}
						name="boatType"
						value={props.userProfile.boatType || ""}
						error={props.errors.boatType}
					/>
					</div>
					</div>
					<div className="four-column-row">
				<div className="form-input column">
					<TextInput
						id="boatModel"
						label="Boat model"
						onChange={props.onTextChange}
						name="boatModel"
						value={props.userProfile.boatModel || ""}
						error={props.errors.boatModel}
					/>
					</div>
				<div className="form-input column">
					<TextInput
						id="boatYear"
						label="Boat year"
						onChange={props.onTextChange}
						name="boatYear"
						value={props.userProfile.boatYear || ""}
						type="number"
						error={props.errors.boatYear}
					/>
					</div>
				<div className="form-input column">
					<TextInput
						id="boatLength"
						label="Boat length"
						onChange={props.onTextChange}
						name="boatLength"
						value={props.userProfile.boatLength}
						error={props.errors.boatLength}
					/>
					</div>
				<div className="form-input column">
					<TextInput
						id="boatDraft"
						label="Boat draft"
						onChange={props.onTextChange}
						name="boatDraft"
						value={props.userProfile.boatDraft}
						error={props.errors.boatDraft}
					/>
					</div>
					</div>
					<br />
					<p className="small">This section is optional and won't be displayed in your profile unless filled in.</p>
					<br />
					<hr></hr>
					<br />
					<div className="terms alert alert-primary">
						<h3>Our Community Standards</h3>
						<p>We have built Explore with Imray to provide a space for social interactions around sailing activities. Our community standards are a roadmap for contributing to this diverse, globe-trotting community.</p>
						<br />
						<ul>
							<li><b>Be Kind</b><br /><span>Treat others with respect and kindness on our digital platform. Don’t use hate speech or offensive language, or post anything that may intimidate, exclude or silence others on the platform.</span></li>
							<li><b>Be Creative</b><br /><span>Create your own amazing content and tell the world of your adventures. If you copy someone else’s content it may be taken down in addition to other further consequences.</span></li>
							<li><b>Be Responsible</b><br /><span>Make sure that you are being responsible towards rules, laws and the environment of the places you visit and post about. Don’t overstep the boundaries in search of a good story to share. Obey the laws and rules of places you visit, and respect the environment you are in. Remember that others might be inspired by your stories. Try to only include information that you know to be correct.</span></li>
							<li><b>Be Yourself</b><br /><span>Tell your stories. Don’t impersonate other individuals, groups or organisations. Don’t spam other users or locations on the platform.</span></li>
						</ul>
						<br />
						<br />
						<p>Thanks, Explore with Imray team.</p>
					</div>
					<br />
					<p className="small">By creating your Explore with Imray profile you are agreeing to our terms of use and with our community standards policies. Explore with Imray reserves the right to remove social user accounts and social posts that violate our terms of use or do not share our values without prior notice. For more information please see our <a href="https://www.explorewithimray.com/community-standards" target="_blank" title="Community Standards">Community Standards</a>, our <a href="https://www.explorewithimray.com/privacy-policy" target="_blank" title="Privacy Policy">privacy policy</a> and our <a href="https://www.explorewithimray.com/terms-conditions" target="_blank" title="Terms and Conditions">terms of use</a>.</p>
					<br />
				<div className="">
					<input type="submit" value="Update my profile" className="btn btn-primary" />
					<a href={props.exploreURL} className="btn btn-secondary">Cancel changes</a>
					</div>
				</div>
			</form>
		</Container>
	)
}

export default UserProfileForm;

