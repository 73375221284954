import React, { useRef } from "react";
import PropType from "prop-types";
import Select from "react-select"
import { useState, useEffect } from "react";

function SelectInput(props) {
	const inputElement = useRef(null);

    const [inputProperties, setInputPropertiesInternal] = useState({ className: "select-box" });
    const inputPropertiesRef = useRef(inputProperties);
    function updateInputProperties(newState) {
        inputPropertiesRef.current = newState;
        setInputPropertiesInternal(newState);
	}

    useEffect(() => {
        setClassName(false);
    }, [props.value, props.error, props.options]);

    function setClassName(focused) {
	    var className = "select-box";

	   //alert(props.id + " is " + typeof (props.value));

	    var isObjectTypeWithValue = props.value !== null &&
		    typeof (props.value) !== "undefined" &&
		    Object.keys(props.value) !== null &&
			Object.keys(props.value).length > 0;

		var isStringTypeWithValue = props.value !== null &&
			typeof (props.value) !== "undefined" &&
			props.value !== "";

		if (focused || isObjectTypeWithValue || isStringTypeWithValue) {
			// user focused on element
		     className += " has-value";
		}

		// hard code this for now!
		className += " has-value";

        if (props.error) {
            className += " is-invalid";
        }

        var isDisabled = false;
        if (props.isDisabled != null && props.isDisabled) {
            className += " is-disabled";
            isDisabled = true;
        }

        updateInputProperties({ ...inputPropertiesRef.current, className: className, isDisabled: isDisabled })
    }

    function onFocus() {
        setClassName(true);
	}

	function labelClick() {
		inputElement.current.focus();
	}

    return (
        <div className="form-group">
			<Select
				ref={inputElement}
                id={props.id}
                value={props.value}
                onChange={props.onChange}
				onFocus={onFocus}
				isMulti={props.isMulti || false}
				options={props.options}
                className={inputProperties.className}
                isDisabled={inputProperties.isDisabled} />
            <label onClick={labelClick} htmlFor={props.id}>{props.label}{props.error && <span className="badge badge-danger">{props.error}</span>}</label>
        </div>
    );
}

SelectInput.propTypes = {
    id: PropType.string.isRequired,
    label: PropType.string.isRequired,
    onChange: PropType.func.isRequired,
    options: PropType.array.isRequired,
	error: PropType.string,
	isDisabled: PropType.bool
};

SelectInput.defaultProps = {
    error: ""
}

export default SelectInput;