import React from "react";
import { useState, useEffect } from "react";
import { Container } from 'reactstrap';
import MapBox from "../../common/MapBox";
import DateInput from "../../common/DateInput";
import TextInput from "../../common/TextInput";
import SelectInput from "../../common/SelectInput";
import CloudinaryUploadWidget from "../../common/CloudinaryUploadWidget";
import useDidMountEffect from '../../../customHooks/useDidMountEffect';
import PostImages from "../components/PostImages";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';

function SocialPostForm(props) {
    const cloudinarySettings = props.cloudinarySettings;

    const [titleTextLimit, setTitleTextLimit] = useState(props.titleTextLimit);
    const [bodyTextLimit, setBodyTextLimit] = useState(props.bodyTextLimit);

    const imageIds = props.socialPost.images?.split(';').filter(id => id) ?? [];
    const maxFiles = 4 - imageIds.length;

    useDidMountEffect(() => {
        if (props.socialPost !== null && props.socialPost !== undefined && props.socialPost.title !== null && props.socialPost.title !== undefined) {
            setTitleTextLimit(props.titleTextLimit - props.socialPost.title.length);
        }

        if (props.socialPost !== null && props.socialPost !== undefined && props.socialPost.body !== null && props.socialPost.body !== undefined) {
            setBodyTextLimit(props.bodyTextLimit - props.socialPost.body.length);
        }

    }, [props.socialPost]);

    return (
        <Container>
        <form onSubmit={props.onSubmit}>
            <div className="form-container">
                <MapBox
                    lng={props.socialPost.longitude}
                    lat={props.socialPost.latitude}
                    zoom={props.zoom}
                />
                <div className="form-input">
                    <TextInput
                        id="title"
                        label="Post title"
                        onChange={props.onTextChange}
                        name="title"
                        value={props.socialPost.title || ""}
                        error={props.errors.title}
                    />
                    <span className="input-character-limit">{titleTextLimit} characters remaining</span>
                </div>
                <br />
                <div className="form-input">
                    <TextInput
                        id="body"
                        label="Body"
                        onChange={props.onTextChange}
                        name="body"
                        value={props.socialPost.body || ""}
                        error={props.errors.body}
                        multiline={true}
                    />
                    <span className="input-character-limit">{bodyTextLimit} characters remaining</span>
                    </div>
                    <br />
                    {cloudinarySettings &&
                        <div className="form-input">
                            {maxFiles > 0 &&
                                <CloudinaryUploadWidget
                                    cloudName={cloudinarySettings?.cloud}
                                    uploadPreset={cloudinarySettings?.postUploadPreset}
                                    api_key={cloudinarySettings?.apiKey}
                                    caption={`Upload images`}
                                    onImageUpload={props.onImageUpload}
                                    maxFiles={maxFiles}
                                    multiple={true}
                                />
                            }
                            <PostImages
                                cloudName={cloudinarySettings?.cloud}
                                images={imageIds}
                                onImageDelete={props.onImageDelete}
                            />
                        </div>
                    }
                        <br />
                    <p className="small">You can upload up to 4 images to your post. You have {maxFiles} images remaining.</p>
                    <hr></hr>
                    <br />
                    <p className="small">Information which helps you to navigate is included in the pilot book data on the platform, on printed books and charts, and chart plotters and apps.  The social features on Explore with Imray are intended as a place to record your stories and to tell your adventures to the world.</p>
                    <br />
                    <div>
                        <input type="submit" value="Share post" className="btn btn-primary" />
                        <button onClick={props.handleCancel} className="btn btn-secondary">Cancel</button>
                    </div>
                    <br />
                    <br />
                    <div className="terms alert alert-primary">
                        <h3>Our Community Standards</h3>
                        <br />
                        <ul>
                            <li><b>Be Kind</b><br /><span>Treat others with respect and kindness on our digital platform. Don’t use hate speech or offensive language, or post anything that may intimidate, exclude or silence others on the platform.</span></li>
                            <li><b>Be Creative</b><br /><span>Create your own amazing content and tell the world of your adventures. If you copy someone else’s content it may be taken down in addition to other further consequences.</span></li>
                            <li><b>Be Responsible</b><br /><span>Make sure that you are being responsible towards rules, laws and the environment of the places you visit and post about. Don’t overstep the boundaries in search of a good story to share. Obey the laws and rules of places you visit, and respect the environment you are in. Remember that others might be inspired by your stories. Try to only include information that you know to be correct.</span></li>
                            <li><b>Be Yourself</b><br /><span>Tell your stories. Don’t impersonate other individuals, groups or organisations. Don’t spam other users or locations on the platform.</span></li>
                        </ul>
                    </div>
                    <br />
                    <p className="small">By being an active Explore with Imray user you are agreeing to our terms of use and our community standards policies. Explore with Imray reserves the right to remove social user accounts and social posts that violate our terms of use or do not share our values without prior notice. For more information please see our <a href="https://www.explorewithimray.com/community-standards" target="_blank" title="Community Standards">Community Standards</a> and our <a href="https://www.explorewithimray.com/terms-conditions" target="_blank" title="Terms and Conditions">terms of use</a>.</p>
            </div>
            </form>
            </Container>
    )
}

export default SocialPostForm;