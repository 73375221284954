import React from "react";
import { AuthContext } from "./authProvider.jsx";

export function Callback(props) {
    const authContext = React.useContext(AuthContext);
    try {
        authContext.signinRedirectCallback();
    }
    catch (e) {
        if (e.message.indexOf("login_required") > 0) {
            console.log("login required")
        }
        else {
            console.error(e);
        }
    }
    return (<p></p>)
}
