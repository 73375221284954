import React from "react";
import PropType from "prop-types";

function PostImages(props) {
    return (
        <div className = "post-edit-images">
            {props.images.map(public_id => {
                if (!public_id) return;

                const url = `https://res.cloudinary.com/${props.cloudName}/image/upload/c_limit,h_60,w_90/${public_id}`;
                return (
                    <span className="image" key={public_id}>
                        <img src={url} />
                        <div type="button" onClick={() => props.onImageDelete(public_id)} className="delete badge rounded-pill bg-danger">X</div>
                    </span>
                );
            })}
        </div>
    );
}

PostImages.propTypes = {
    cloudName: PropType.string.isRequired,
    images: PropType.array.isRequired,
    onImageDelete: PropType.func.isRequired,
};

export default PostImages;
