import React from "react";
import PropType from "prop-types";
import { useState, useEffect } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import enGb from 'date-fns/locale/en-GB';
import "react-datepicker/dist/react-datepicker.css";
registerLocale('en-GB', enGb);

function DateInput(props) {
    const [inputProperties, setInputProperties] = useState({ className: "form-control" });
	const [value, setValue] = useState(null);

    useEffect(() => {
        var className = "form-control";
        if (props.value !== null && props.value !== "") {
            className += " has-value";
        }
        if (props.error) {
            className += " is-invalid";
        }

        setInputProperties({
            ...inputProperties, className: className
		});

		if (Object.prototype.toString.call(props.value) === '[object Date]') {
			// if date, use  directly
			setValue(props.value);
		} else if (props.value !== null) {
			// if string, cast to date
			setValue(new Date(props.value));
		}
    }, [props.value, props.error]);

	function onChange(date) {
		//setValue(date);
		props.onChange({target : { name: props.name, value: date }});
	}

    return (
		<div className="form-group">
			<DatePicker
                selected={value}
                locale="en-GB"
                dateFormat="yyyy-MM-dd"
				onChange={date => onChange(date)} />
            <label htmlFor={props.id}>{props.label}{props.error && <span className="badge badge-danger">{props.error}</span>}</label>
        </div>
    );
}

DateInput.propTypes = {
    id: PropType.string.isRequired,
    name: PropType.string.isRequired,
    label: PropType.string.isRequired,
    onChange: PropType.func.isRequired,
    value: PropType.oneOfType([
        PropType.instanceOf(Date),
        PropType.string
    ]),
    error: PropType.string
};

DateInput.defaultProps = {
    error: ""
}

export default DateInput;