import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import register from './registerServiceWorker';
import { AuthProvider } from './components/auth/authProvider.jsx';
import App from './App'
const rootElement = document.getElementById('root');

ReactDOM.render(
    <React.StrictMode>
        <AuthProvider>
            <App />
        </AuthProvider>
    </React.StrictMode>
	,
  rootElement);

register();
