import { urlForSearch, handleError, sendDataItemToFixedUrl, getResultsFromDataAtUrl, sendDataItemToUrl, deleteItemAtBaseUrl, getOptionsWithAuthHeader, handleErrorResponse, getResultFromDataAtUrl } from "./../apiUtils";
const baseUrl = "/api/appSettings/";

export function getExploreUrl() {
	return getResultFromDataAtUrl(baseUrl + "getExploreUrl/");
}

export function getUserProfileCloudinarySettings() {
	return getResultFromDataAtUrl(baseUrl + "getUserProfileCloudinarySettings/");
}

export function getCloudinarySignature(params_to_sign) {
	return sendDataItemToUrl(baseUrl + "getCloudinarySignature/", params_to_sign);
}