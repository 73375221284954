import React from "react";
import SocialPostEditForm from "./SocialPostEditForm";
import { Container } from 'reactstrap';
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import * as socialPostApi from "../../../api/socialPostApi";
import * as socialPostPartialApi from "../../../api/partials/socialPostApi";
import * as appSettingsApi from "../../../api/partials/appSettingsApi";
import { AuthContext } from "../../auth/authProvider.jsx";

function SocialPostEditPage(props) {
    const authContext = React.useContext(AuthContext);

    const [errors, setErrors] = useState({});
    const [exploreURL, setExploreURL] = useState(null);
    const [zoomLevel, setLevelZoom] = useState(3);
    const [cloudinarySettings, setCloudinarySettings] = useState(null);
    const [socialPost, setSocialPost] = useState({
        id: "00000000-0000-0000-0000-000000000000",
        userId: "00000000-0000-0000-0000-000000000000",
        name: "",
        title: "",
        body: "",
        latitude: 0,
        longitude: 0,
        images: "",
        Permalink: ""
    });
    const titleTextLimit = 70;
    const bodyTextLimit = 1000;

    useEffect(() => {
        appSettingsApi.getExploreUrl()
            .then(result => {
                setExploreURL(result);
            });

        appSettingsApi.getUserProfileCloudinarySettings()
            .then(result => {
                setCloudinarySettings(result);
            });
    }, []);

    // Runs if 'key' changes, but not on initial render
    useEffect(() => {
        async function getSocialPostAsync(id) {
            var accessToken = await authContext.getAccessToken(true);

            await socialPostApi.getSocialPostById(id, accessToken)
                .then(socialPost => {
                    setSocialPost(socialPost);
                    configureSelectInputs(socialPost)
                })
                .catch(error => {
                    if (error === 'Unauthorised') {
                        authContext.signinRedirect();
                    } else {
                        console.log(error);
                    }
                })
        }

        const hasLong = props.match.params.lat !== null && props.match.params.lat !== undefined && props.match.params.lat !== "";
        const hasLat = props.match.params.long !== null && props.match.params.long !== undefined && props.match.params.long !== "";
        const hasZoom = props.match.params.zoom !== null && props.match.params.zoom !== undefined && props.match.params.zoom !== "";
        const id = props.match.params.id;
        const long = hasLong ? parseFloat(props.match.params.long) : 14.0;
        const lat = hasLat ? parseFloat(props.match.params.lat) : 44.00;
        const zoom = hasZoom ? parseFloat(props.match.params.zoom) : 3;

        setLevelZoom(zoom);

        // If we have the lat log parameters then set them, otherwise we're editing and don't need to
        if (hasLong && hasLat) {
            setLongAndLat(long, lat);
        }

        if (id) {
            getSocialPostAsync(id);
        } else {
            configureSelectInputs(null);
        }

    }, [exploreURL], [props.match.params.id]);

    function configureSelectInputs(socialPost) {
    }

    function handleTextChange({ target }) {
        const updatedSocialPost = { ...socialPost, [target.name]: target.value };
        setSocialPost(updatedSocialPost);
    }

    function handleSelectChange(selectedValue) {
        const updatedSocialPost = { ...socialPost, [selectedValue.ProductPropertyIdName]: selectedValue.id };
        setSocialPost(updatedSocialPost);
        console.debug("Setting " + selectedValue.ProductPropertyIdName + " to " + selectedValue.id);
    }

    function setLongAndLat(long, lat) {
        setSocialPost({
            ...socialPost,
            ["longitude"]: long,
            ["latitude"]: lat
        });
    }

    function handleCheckboxChange({ target }) {
        const updatedSocialPost = { ...socialPost, [target.name]: target.checked };
        setSocialPost(updatedSocialPost);
    }

    function handleImageUpload(uploadResult) {
        const updatedSocialPost = { ...socialPost, ["images"]: `${socialPost.images ?? ""}${uploadResult.public_id};` };
        setSocialPost(updatedSocialPost);
    }

    function handleImageDelete(imageId) {
        const images = socialPost.images.replace(`${imageId};`, '');
        const updatedSocialPost = { ...socialPost, ["images"]: images };
        setSocialPost(updatedSocialPost);
    }

    function formIsValid() {
        const _errors = {};

        if (socialPost.title && socialPost.title.length > titleTextLimit) _errors.title = `Title must be ${titleTextLimit} characters or less`;
        if (!socialPost.title) _errors.title = "Title is required";
        if (socialPost.body && socialPost.body.length > bodyTextLimit) _errors.body = `Body must be ${bodyTextLimit} characters or less`;
        if (!socialPost.body) _errors.body = "Body is required";

        setErrors(_errors);
        // form is valid if the errors object has no properties.
        return Object.keys(_errors).length === 0;
    }

    async function handleSubmit(event) {
        event.preventDefault();
        if (!formIsValid()) return;

        var accessToken = await authContext.getAccessToken(true);

        await socialPostPartialApi.saveUserSocialPost(socialPost, accessToken)
            .then((socialPostId) => {
                toast.success("User profile saved.");
                window.location.replace(`${exploreURL}?lng=${socialPost.longitude}&lat=${socialPost.latitude}&zoom=${zoomLevel}`);
            })
            .catch(error => {
                if (error === 'Unauthorised') {
                    authContext.signinRedirect();
                } else {
                    console.log(error);
                }
            })
    }

    function handleCancel(event) {
        window.location.replace(`${exploreURL}${socialPost.longitude}/${socialPost.latitude}/${zoomLevel}`);
    }

    function GetHeader() {
        if (socialPost.id) {
            return <Container>
                <br />
                <br />
                <a className="btn-pill" href={exploreURL}>
                    <span className="material-icons">keyboard_backspace</span>
                    <span className="pill-label">Back to Explore with Imray</span>
                </a>
                <br />
                <br />
                <h1>Share your story</h1>
                <p>Create a journal style post and bring your sailing adventures to life.</p>
                <hr></hr>
            </Container>
        }
        return <Container>
            <br />
            <br />
            <a className="btn-pill" href={exploreURL}>
                <span className="material-icons">keyboard_backspace</span>
                <span className="pill-label">Back to Explore with Imray</span>
            </a>
            <br />
            <br />
            <h1>Create a post</h1>
            <p>Content about createing and sharing posts.</p>
            <hr></hr>
        </Container>
    }

    return (
        <>
            {socialPost !== null &&
                <>
                    <GetHeader />
                    <SocialPostEditForm
                        socialPost={socialPost}
                        errors={errors}
                        onTextChange={handleTextChange}
                        onSelectChange={handleSelectChange}
                        onCheckboxChange={handleCheckboxChange}
                        onImageUpload={handleImageUpload}
                        onImageDelete={handleImageDelete}
                        onSubmit={handleSubmit}
                        handleCancel={handleCancel}
                        zoom={zoomLevel}
                        titleTextLimit={titleTextLimit}
                        cloudinarySettings={cloudinarySettings}
                        bodyTextLimit={bodyTextLimit}
                    />
                </>
            }
            {socialPost === null &&
                <p>Loading</p>
            }
        </>
    );
}

export default SocialPostEditPage;