import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import queryString from 'query-string';
import SocialPostList from './SocialPostList';
import Loading from './../common/Loading';
import * as socialPostApi from './../../api/socialPostApi';

function SocialPostsPage(props) {
	const [isLoading, setIsLoading] = useState(true);	
	const [rights, setRights] = useState({read:false,create:false,update:false,delete:false});
	const [paging, SetPaging] = useState(null);
	const [socialPosts, SetSocialPosts] = useState([]);
	const [search, setSearch] = useState({
		page : 1, 
		name: '',
	});

	useEffect(() => {
		let query = window.location.search;
		let params = new URLSearchParams(query);
		setSearch({
			...search,
			name: params.get('name') || '',
		});
		
		socialPostApi
			.getRights()
			.then(x => setRights(x));

	}, []);
	
	useEffect(() => {
		setIsLoading(true);
		window.history.pushState({}, null, socialPostApi.generateSearchUrl("/socialPosts", search, ""));
		socialPostApi
			.getSocialPostsFilterByNameAndCode(search)
			.then((_socialPostResponse) => {
					SetSocialPosts(_socialPostResponse.results);
					SetPaging(_socialPostResponse.paging);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [search]);

	function handleSearchChange(event) {
		const { name, value } = event.target;
		setSearch({
			...search,
			[name]: value
		});
	}
	
	function handlePageSelectedChange(newSelectedPageNumber) {
		setSearch({
			...search,
			page: parseInt(newSelectedPageNumber)
		});
	}

	function handleSearchSubmit(event) {
		
	}

	function handleDeleteClicked(socialPost) {
		socialPostApi.deleteSocialPost(socialPost)
			.then(() => {
				SetSocialPosts(socialPosts.filter(_socialPost => _socialPost.id !== socialPost.id));
				toast.success("social post \"" + socialPost.name + "\" successfully deleted!");
			})
			.catch(() => {
				toast.error("Could not delete social post \"" + socialPost.name + "\"!");
			});
	}


	return (
		<>
			{rights.create &&
				<Link className="add-socialPost-button add-button btn btn-primary float-right" to="/socialPosts/add-new/">
					Add social post
				</Link>
			}
			<h2>Social posts</h2>
			<SocialPostList 
				socialPosts={socialPosts}
				canDelete={rights.delete}
				canUpdate={rights.update}
				onDeleteClick={handleDeleteClicked} 
				handleSearchSubmit={handleSearchSubmit} 
				handleSearchChange={handleSearchChange} 
				onPageSelectedChange={handlePageSelectedChange}
				paging={paging} 
				search={search} />
			<Loading isVisible={isLoading} />
		</>
	);
}

export default SocialPostsPage;