import React, { Component } from "react";
import { Route, Switch } from "react-router";

import { BrowserRouter } from "react-router-dom";
import { Callback } from './components/auth/callback';
import { AuthProvider } from './components/auth/authProvider.jsx';
import { PrivateRoute } from './components/auth/privateRoute.jsx';
import { Signout } from './components/auth/signout.jsx';
import { SignoutCallback } from './components/auth/signoutCallback.jsx';

import { Layout } from "./components/Layout";
import { Home } from "./components/Home";
import UserProfileEditPage from "./components/userProfile/edit/UserProfileEditPage";
import UserProfileViewPage from "./components/userProfile/view/UserProfileViewPage";
import UserProfilesPage from "./components/userProfile/UserProfilesPage";
import SocialPostEditPage from "./components/socialPost/edit/SocialPostEditPage";
import SocialPostViewPage from "./components/socialPost/view/SocialPostViewPage";
import SocialPostsPage from "./components/socialPost/SocialPostsPage";

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <>
                <AuthProvider>
                    <BrowserRouter>
                        <Layout>
                            <Switch>
                                <Route exact={true} path="/signin-oidc/" component={Callback} />
                                <Route exact={true} path="/logout/" component={Signout} />
                                <Route exact={true} path="/signout-oidc/" component={SignoutCallback} />
                                <PrivateRoute exact path="/" component={UserProfileEditPage} />
                                <PrivateRoute path="/social-posts/:long/:lat/:zoom/add-new/" component={SocialPostEditPage} />
                                <PrivateRoute path="/social-posts/:id/edit/" component={SocialPostEditPage} />
                                <PrivateRoute path="/user-profile" component={UserProfileEditPage} />
                            </Switch>
                        </Layout>
                    </BrowserRouter>
                </AuthProvider>
            </>
        );
    }
}
