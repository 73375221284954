import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';

export class Layout extends Component {
	static displayName = Layout.name;

	render() {
		return (
			<>
				<NavMenu />
				{this.props.children}
				<Container className="footer"><hr></hr><div><div className="left"><span className="small">&copy;{(new Date().getFullYear())} Imray, Laurie, Norie and Wilson Ltd</span></div><div className="right"><a href="https://www.explorewithimray.com/privacy-policy" target="_blank" title="Privacy policy">Privacy policy</a><a href="https://www.explorewithimray.com/terms-conditions" target="_blank" title="Terms of use">Terms of use</a><a href="https://share.hsforms.com/1boX2mm3UQ9WfJQI8davdLw4udgz" target="_blank" title="Feedback & support">Feedback & support</a></div></div></Container>
			</>
		);
	}
}