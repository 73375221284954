import React from "react";
import UserProfileEditForm from "./UserProfileEditForm";
import { Container } from 'reactstrap';
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import * as userProfileApi from "../../../api/partials/userProfileApi";
import * as appSettingsApi from "../../../api/partials/appSettingsApi";
import { AuthContext } from "../../auth/authProvider.jsx";

function UserProfileEditPage(props) {
    const authContext = React.useContext(AuthContext);

    const [errors, setErrors] = useState({});
    const [exploreURL, setExploreURL] = useState(null);
    const [cloudinarySettings, setCloudinarySettings] = useState(null);
    const [userProfile, setUserProfile] = useState({
        id: null,
        name: "",
        firstName: "",
        lastName: "",
        username: "",
        bio: "",
        currentPort: "",
        boatName: "",
        boatType: "",
        boatModel: "",
        boatYear: "",
        boatLength: "",
        boatDraft: "",
        imageId: null,
        lastUpdated: new Date(),
        created: new Date(),
        hasCompletedProfile: false,
        hasDismissedCompleteProfilePopup: false
    });
    const bioTextLimit = 70;

    useEffect(() => {
        appSettingsApi.getExploreUrl()
            .then(result => {
                setExploreURL(result);
            });

        appSettingsApi.getUserProfileCloudinarySettings()
            .then(result => {
                setCloudinarySettings(result);
            });
    }, []);

    // Runs if 'key' changes, but not on initial render
    useEffect(() => {
        async function getUserProfileAsync() {
            var accessToken = await authContext.getAccessToken(true);

            await userProfileApi
                .getMyUserProfile(accessToken)
                .then(userProfile => {
                    setUserProfile(userProfile);
                    configureSelectInputs(userProfile)
                })
                .catch(error => {
                    if (error === 'Unauthorised') {
                        authContext.signinRedirect();
                    } else {
                        console.log(error);
                    }
                })
        }

        getUserProfileAsync();

    }, [exploreURL]);

    function configureSelectInputs(userProfile) {
    }

    function handleTextChange({ target }) {
        const updatedUserProfile = { ...userProfile, [target.name]: target.value };
        setUserProfile(updatedUserProfile);
    }

    function handleSelectChange(selectedValue) {
        const updatedUserProfile = { ...userProfile, [selectedValue.ProductPropertyIdName]: selectedValue.id };
        setUserProfile(updatedUserProfile);
        console.debug("Setting " + selectedValue.ProductPropertyIdName + " to " + selectedValue.id);
    }

    function handleCheckboxChange({ target }) {
        const updatedUserProfile = { ...userProfile, [target.name]: target.checked };
        setUserProfile(updatedUserProfile);
    }

    function handleImageUpload(uploadResult) {
        const updatedUserProfile = { ...userProfile, ["imageId"]: uploadResult.public_id };
        setUserProfile(updatedUserProfile);
    }

    function formIsValid() {
        const _errors = {};

        if (userProfile.firstName && userProfile.firstName.length > 1000) _errors.firstName = "First name must be 1000 characters or less";
        if (!userProfile.firstName) _errors.firstName = "First name is required";
        if (userProfile.lastName && userProfile.lastName.length > 1000) _errors.lastName = "Last name must be 1000 characters or less";
        if (!userProfile.lastName) _errors.lastName = "Last name is required";
        if (userProfile.bio && userProfile.bio.length > bioTextLimit) _errors.bio = `Bio must be ${bioTextLimit} characters or less`;
        if (userProfile.currentPort && userProfile.currentPort.length > 100) _errors.currentPort = "Home port must be 100 characters or less";
        if (userProfile.boatName && userProfile.boatName.length > 100) _errors.boatName = "Boat name must be 100 characters or less";
        if (userProfile.boatType && userProfile.boatType.length > 100) _errors.boatType = "Boat type must be 100 characters or less";
        if (userProfile.boatModel && userProfile.boatModel.length > 100) _errors.boatModel = "Boat model must be 100 characters or less";
        if (userProfile.boatYear && userProfile.boatYear.length > 4) _errors.boatYear = "Boat year must be 4 characters or less";
        if (userProfile.boatLength && userProfile.boatLength.length > 100) _errors.boatLength = "Boat length must be 100 characters or less";
        if (userProfile.boatDraft && userProfile.boatDraft.length > 100) _errors.boatDraft = "Boat draft must be 100 characters or less";

        setErrors(_errors);
        // form is valid if the errors object has no properties.
        return Object.keys(_errors).length === 0;
    }

    async function handleSubmit(event) {
        event.preventDefault();
        if (!formIsValid()) return;

        var accessToken = await authContext.getAccessToken(true);

        await userProfileApi
            .saveUserProfile(userProfile, accessToken)
            .then(userProfileId => {
                toast.success("User profile saved.");
                window.location.replace(exploreURL);
            })
            .catch(error => {
                if (error === 'Unauthorised') {
                    authContext.signinRedirect();
                } else {
                    console.log(error);
                }
            })
    }

    function GetHeader() {
        if (userProfile.id) {
            return <Container>
                <br />
                <br />
                <a className="btn-pill" href={exploreURL}>
                    <span className="material-icons">keyboard_backspace</span>
                    <span className="pill-label">Back to Explore with Imray</span>
                </a>
                <br />
                <br />
                <h1>Your profile</h1>
                <p>Here is the information related to your public Explore with Imray social profile. You can modify your personal and boat information anytime. Your unique user name is @{userProfile.name}.</p>
                <hr></hr>
            </Container>
        }
        return <Container>
            <br />
            <br />
            <a className="btn-pill" href={exploreURL}>
                <span className="material-icons">keyboard_backspace</span>
                <span className="pill-label">Back to Explore with Imray</span>
            </a>
            <br />
            <br />
            <h1>Your profile</h1>
            <p>Here is the information related to your public Explore with Imray social profile. You can modify your personal and boat information anytime. Your unique user name is @{userProfile.name}.</p>
            <hr></hr>
        </Container>
    }

    return (
        <>
            {userProfile !== null &&
                <>
                    <GetHeader />
                    <UserProfileEditForm
                        userProfile={userProfile}
                        errors={errors}
                        onTextChange={handleTextChange}
                        onSelectChange={handleSelectChange}
                        onCheckboxChange={handleCheckboxChange}
                        onImageUpload={handleImageUpload}
                        onSubmit={handleSubmit}
                        exploreURL={exploreURL}
                        cloudinarySettings={cloudinarySettings}
                        bioTextLimit={bioTextLimit}
                    />
                </>
            }
            {userProfile === null &&
                <p>Loading</p>
            }
        </>
    );
}

export default UserProfileEditPage;