import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';

mapboxgl.accessToken = 'pk.eyJ1IjoiaW1yYXlvbmxpbmUiLCJhIjoiY2tnN3FpbmdvMDZkNTJ4bHM3OTMzNXhoaCJ9.reVelvS48I7PO96bJ2VZQA';

export default function MapBox(props) {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(props.lng.toFixed(4));
    const [lat, setLat] = useState(props.lat.toFixed(4));
    const [zoom, setZoom] = useState(props.zoom.toFixed(2));

    useEffect(() => {
        if (map.current) {
            // map already initialized, update position
            map.current.jumpTo({
                center: [props.lng, props.lat],
                zoom: props.zoom,
            });

            new mapboxgl.Marker()
                .setLngLat([props.lng, props.lat])
                .addTo(map.current);

            return;
        }

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/imrayonline/ckg7w26bj5z1019olz49dhfuq',
            center: [props.lng, props.lat],
            zoom: props.zoom,
            interactive: false
        });
    });

    useEffect(() => {
        if (!map.current) return; // wait for map to initialize
        map.current.on('move', () => {
            setLng(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(2));
        });
    });

    return (
        <div>
            <div className="map-sidebar">
                Longitude: {lng} | Latitude: {lat}
            </div>
            <div ref={mapContainer} className="map-container" />
        </div>
    );
}