import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import queryString from 'query-string';
import UserProfileList from './UserProfileList';
import Loading from './../common/Loading';
import * as userProfileApi from './../../api/userProfileApi';

function UserProfilesPage(props) {
	const [isLoading, setIsLoading] = useState(true);	
	const [rights, setRights] = useState({read:false,create:false,update:false,delete:false});
	const [paging, SetPaging] = useState(null);
	const [userProfiles, SetUserProfiles] = useState([]);
	const [search, setSearch] = useState({
		page : 1, 
		name: '',
	});

	useEffect(() => {
		let query = window.location.search;
		let params = new URLSearchParams(query);
		setSearch({
			...search,
			name: params.get('name') || '',
		});
		
		userProfileApi
			.getRights()
			.then(x => setRights(x));

	}, []);
	
	useEffect(() => {
		setIsLoading(true);
		window.history.pushState({}, null, userProfileApi.generateSearchUrl("/userProfiles", search, ""));
		userProfileApi
			.getUserProfilesFilterByNameAndCode(search)
			.then((_userProfileResponse) => {
					SetUserProfiles(_userProfileResponse.results);
					SetPaging(_userProfileResponse.paging);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [search]);

	function handleSearchChange(event) {
		const { name, value } = event.target;
		setSearch({
			...search,
			[name]: value
		});
	}
	
	function handlePageSelectedChange(newSelectedPageNumber) {
		setSearch({
			...search,
			page: parseInt(newSelectedPageNumber)
		});
	}

	function handleSearchSubmit(event) {
		
	}

	function handleDeleteClicked(userProfile) {
		userProfileApi.deleteUserProfile(userProfile)
			.then(() => {
				SetUserProfiles(userProfiles.filter(_userProfile => _userProfile.id !== userProfile.id));
				toast.success("user profile \"" + userProfile.name + "\" successfully deleted!");
			})
			.catch(() => {
				toast.error("Could not delete user profile \"" + userProfile.name + "\"!");
			});
	}


	return (
		<>
			{rights.create &&
				<Link className="add-userProfile-button add-button btn btn-primary float-right" to="/userProfiles/add-new/">
					Add user profile
				</Link>
			}
			<h2>User profiles</h2>
			<UserProfileList 
				userProfiles={userProfiles}
				canDelete={rights.delete}
				canUpdate={rights.update}
				onDeleteClick={handleDeleteClicked} 
				handleSearchSubmit={handleSearchSubmit} 
				handleSearchChange={handleSearchChange} 
				onPageSelectedChange={handlePageSelectedChange}
				paging={paging} 
				search={search} />
			<Loading isVisible={isLoading} />
		</>
	);
}

export default UserProfilesPage;