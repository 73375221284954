import React, { Component } from "react";
import * as appSettingsApi from "../../api/partials/appSettingsApi";

class CloudinaryUploadWidget extends Component {
    uploadWidget = null;

    componentDidMount() {
        var widget = window.cloudinary.createUploadWidget(
            {
                public_id: this.props.public_id,
                cloudName: this.props.cloudName,
                uploadPreset: this.props.uploadPreset,
                api_key: this.props.api_key,
                resourceType: "image",
                sources: ["local", "camera"],
                uploadSignature: this.generateSignature,
                showUploadMoreButton: false,
                multiple: this.props.multiple,
                maxFiles: this.props.maxFiles,
                //overwrite: true,
                //invalidate: true,
            },
            (error, result) => {
                if (!error && result && result.event === "success") {
                    console.log("Done! Here is the image info: ", result.info);
                    this.props.onImageUpload(result.info);
                }
            }
        );

        this.uploadWidget = widget;
        document.getElementById("upload_widget").addEventListener(
            "click",
            function (e) {
                if (e.preventDefault) e.preventDefault();
                widget.open();
                return false;
            },
            false
        );
    };

    componentDidUpdate() {
        if (this.uploadWidget)
            this.uploadWidget.update({
                public_id: this.props.public_id,
                multiple: this.props.multiple,
                maxFiles: this.props.maxFiles
            });
    };

    generateSignature = async (callback, params_to_sign) => {
        try {
            // getSignature is calling an API, passing the params_to_sign in the request body and returns the signature
            appSettingsApi.getCloudinarySignature(params_to_sign)
                .then(result => {
                    callback(result);
                });
        } catch (err) {
            console.log(err);
        }
    };

    render() {
        return (
            <a href="#" id="upload_widget" className="btn btn-secondary">{this.props.caption}</a>
        );
    }
}

export default CloudinaryUploadWidget;
