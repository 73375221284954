/* /src/routes/privateRoute.jsx */
import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { AuthContext } from "./authProvider.jsx";
import { Spinner } from 'react-bootstrap'

const LoadingFullScreen = () => (
    <div
        style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh'
        }}
    >
        <Spinner animation="border" variant="primary" />
    </div>
)

export function PrivateRoute({ component, ...rest }) {
    const authContext = React.useContext(AuthContext);
    const [useHasAccess, setUserHasAccess] = useState(null);

    useEffect(() => {
        async function fetchData() {
            var _userIsAuthenticated = await authContext.isAuthenticated();
            setUserHasAccess(_userIsAuthenticated);
        }
        fetchData();

    }, [authContext]);


    const renderFn = (Component) => (props) => {

        function Login() {
            authContext.signinRedirect();
        }
        if (useHasAccess === null) {
            return <LoadingFullScreen />;
        }
        else if (!!Component && useHasAccess) {
            return <Component {...props} />;
        } else {
            Login();
            return <LoadingFullScreen />;
        }
    };

    return <Route {...rest} render={renderFn(component)} />;
};