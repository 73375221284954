import { urlForSearch, handleError, getDataFromUrl, getDataFromCacheOrUrl, getResultsFromDataAtUrl, sendDataItemToUrl, deleteItemAtBaseUrl, getOptionsWithAuthHeader, handleErrorResponse, getResultsFromDataAtUrlAsPromise } from "./apiUtils";
const baseUrl = "/api/socialPost/";

export function getRights() {
	return getDataFromUrl(baseUrl + "rights/");
}

export function getSocialPosts() {
		return getDataFromUrl(baseUrl);
}

export function getSocialPostById(id, accessToken) {
	return getResultsFromDataAtUrlAsPromise(baseUrl + id, accessToken);
}

export function getSocialPostsFilterByNameAndCode(search) {
	var url = generateSearchUrl(baseUrl, search, "Search.");
		return getDataFromUrl(url);
}

export function saveSocialPost(socialPost) {
	return sendDataItemToUrl(baseUrl, socialPost);
}

export function deleteSocialPostById(id) {
	return deleteItemAtBaseUrl(baseUrl + id, { id: "" });
}

export function deleteSocialPost(socialPost) {
	return deleteItemAtBaseUrl(baseUrl, socialPost);
}

export function generateSearchUrl(startOfUrl, searchParams, keyPrefix) {
	return urlForSearch(startOfUrl, searchParams, keyPrefix);
}