import React, { useState, useEffect } from 'react'
import {
	Collapse,
	Navbar,
	NavbarBrand,
	NavbarToggler,
	NavItem,
	NavLink,
} from 'reactstrap'
import useDidMountEffect from '../customHooks/useDidMountEffect';
import * as appSettingsApi from "./../api/partials/appSettingsApi";

export function NavMenu(props) {
	const [collapsed, setCollapsed] = useState(true)
	const [menu, setMenu] = useState(null)
	const [exploreURL, setExploreURL] = useState(null);

	const handleToggleNavbar = function () {
		setCollapsed(!collapsed)
	}

	appSettingsApi.getExploreUrl()
		.then(result => {
			setExploreURL(result);
		});

	// Runs if 'key' changes, but not on initial render
	useDidMountEffect(() => {
		setMenu([{ url: exploreURL, text: "Home" }]);
	}, [exploreURL]);

	return (
		<header>
			{menu != null && (
				<Navbar
					light
				>
						<NavbarBrand href={exploreURL}>
							<img id="nav-bar-logo" src="/img/brand/explore-w-logo-blue-large@2x.png"  className="d-inline-block" alt="Explore with Imray" loading="lazy" />
						</NavbarBrand>
				</Navbar>
			)}
			{/*{menu == null && <div>Please wait.</div>}*/}
		</header>
	)
}