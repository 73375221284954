import { UserManager, WebStorageStateStore } from 'oidc-client'

var userManager = null

export const UserManagerAccessor = {
  isUserLoggedIn: async () => {
    return new Promise(resolve => {
      if (userManager === null && isInUserEnvironment()) {
        userManager = new UserManager(getConfig())
      }
      if (userManager === null) {
        resolve(false)
        return
      }
      userManager.getUser().then(user => {
        resolve(user !== null)
      })
    })
  },

  signout: async () => {
    return new Promise(resolve => {
      if (userManager === null && isInUserEnvironment()) {
        userManager = new UserManager(getConfig())
      }
      if (userManager === null) {
        resolve()
        return
      }
      var user = userManager.signoutRedirect().then(() => {
        resolve()
      })
    })
  },

  getAccessToken: async () => {
    return new Promise(resolve => {
      if (userManager === null && isInUserEnvironment()) {
        userManager = new UserManager(getConfig())
      }
      if (userManager === null) {
        resolve(null)
        return
      }
      userManager
        .getUser()
        .then(user => {
          resolve(user.access_token)
        })
        .catch(error => {
          resolve(null)
        })
    })
  }
}

function isInUserEnvironment() {
  return typeof window !== 'undefined' && window !== null
}
if (isInUserEnvironment()) {
  userManager = new UserManager(getConfig())
}

export function getConfig() {
  if (!isInUserEnvironment()) {
    return null
  }

    var port = parseInt(window.location.port) || 443
    var host =
        window.location.protocol +
        '//' +
        window.location.hostname +
        (port !== 443 ? ':' + port : '')
    var authority = 'https://accounts.imray.com/'
    if (
        window.location.hostname === 'localhost' ||
        window.location.hostname === 'social.imray.dev' ||
        window.location.hostname === 'social-local.imray.com'
    ) {
        authority = 'https://id.imray.dev'
    }

  return {
    authority: authority,
    client_id: 'ImraySocialUI',
    redirect_uri: host + '/signin-oidc',
    response_type: 'code',
    scope:
      'openid profile email offline_access socialapi',
    post_logout_redirect_uri: host + '/signout-oidc',
    filterProtocolClaims: true,
    loadUserInfo: true,
    automaticSilentRenew: true,
    revokeAccessTokenOnSignout: true,
    stateStore: new WebStorageStateStore({ store: window.localStorage }),
    userStore: new WebStorageStateStore({ store: window.localStorage })
  }
}

const parseJwt = token => {
  if (!isInUserEnvironment()) return
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace('-', '+').replace('_', '/')
  return JSON.parse(window.atob(base64))
}

export const AuthService = {
  signinRedirectCallback: () => {
    if (!isInUserEnvironment()) return
    if (userManager == null) return
    userManager.signinRedirectCallback().then(user => {
      var redirectUri = localStorage.getItem('redirectUri')
      if (
        typeof redirectUri !== 'undefined' &&
        redirectUri !== null &&
        redirectUri !== ''
      ) {
        window.location.replace(redirectUri)
      } else {
        window.location.replace('/')
      }
    })
  },
  getUser: async redirectIfSilentFailed => {
    if (!isInUserEnvironment()) return null
    if (userManager == null) return null

    localStorage.setItem(
      'redirectUri',
      window.location.pathname + window.location.search
    )

    var user = await userManager.getUser()
    var isAccessTokenExpired =
      user !== null && user !== undefined
        ? checkIfAccessTokenIsExpired(user.expires_at)
        : false

    if (isAccessTokenExpired) {
      try {
        user = await userManager.signinSilent()
      } catch (e) {
        console.error('signinSilent failed', e)
      }
    }

    isAccessTokenExpired =
      user !== null && user !== undefined
        ? checkIfAccessTokenIsExpired(user.expires_at)
        : false

    if (isAccessTokenExpired && redirectIfSilentFailed) {
      user = await userManager.signinRedirect()
      user = await userManager.getUser()
    }

    if (typeof user === 'undefined') {
      user = null
    }

    return user
  },
  getAccessToken: async redirectIfSilentFailed => {
    if (!isInUserEnvironment()) return null
    if (userManager == null) return null

    localStorage.setItem(
      'redirectUri',
      window.location.pathname + window.location.search
    )

    var user = await userManager.getUser()
    var isAccessTokenExpired =
      user !== null && user !== undefined
        ? checkIfAccessTokenIsExpired(user.expires_at)
        : false

    if (isAccessTokenExpired) {
      try {
        user = await userManager.signinSilent()
      } catch (e) {
        console.error('signinSilent failed', e)
      }
    }

    isAccessTokenExpired =
      user !== null && user !== undefined
        ? checkIfAccessTokenIsExpired(user.expires_at)
        : false

    if (isAccessTokenExpired && redirectIfSilentFailed) {
      await userManager.signinRedirect({})
      user = await userManager.getUser()
    }

    if (typeof user === 'undefined' || user === null) {
      return null
    }

    return user.access_token
  },
  getAccessTokenDetails: async redirectIfSilentFailed => {
    if (!isInUserEnvironment()) return null
    if (userManager == null) return null

    localStorage.setItem(
      'redirectUri',
      window.location.pathname + window.location.search
    )

    var user = await userManager.getUser()
    var isAccessTokenExpired =
      user !== null && user !== undefined
        ? checkIfAccessTokenIsExpired(user.expires_at)
        : false

    if (isAccessTokenExpired) {
      try {
        user = await userManager.signinSilent()
      } catch (e) {
        console.error('signinSilent failed', e)
      }
    }

    isAccessTokenExpired =
      user !== null && user !== undefined
        ? checkIfAccessTokenIsExpired(user.expires_at)
        : false

    if (isAccessTokenExpired && redirectIfSilentFailed) {
      await userManager.signinRedirect({})
      user = await userManager.getUser()
    }

    if (typeof user === 'undefined' || user === null) {
      var accessTokenDetails = {
        accessToken: '',
        isExpired: true
      }

      return accessTokenDetails
    }

    var accessTokenDetails = {
      accessToken: user.access_token,
      isExpired: isAccessTokenExpired
    }

    return accessTokenDetails
  },
  isAccessTokenValid: async () => {
    if (!isInUserEnvironment()) return null
    if (userManager == null) return null
    var user = await userManager.getUser()

    return checkIfAccessTokenIsExpired(user.expires_at)
  },
  signinRedirect: () => {
    if (!isInUserEnvironment()) return
    if (userManager == null) return
    localStorage.setItem('redirectUri', window.location.pathname)
    userManager.signinRedirect({})
  },
  navigateToScreen: () => {
    if (!isInUserEnvironment()) return
    window.location.replace('/')
  },
  isAuthenticated: async () => {
    if (!isInUserEnvironment()) return false
    if (userManager == null) return false
    var user = await userManager.getUser()
    return user !== null && user !== undefined && user !== ''
  },
  signinSilent: () => {
    if (!isInUserEnvironment()) return
    if (userManager == null) return
    userManager
      .signinSilent()
      .then(user => {
        return user
      })
      .catch(err => {
        console.log(err)
      })
  },
  signinSilentCallback: () => {
    if (!isInUserEnvironment()) return
    if (userManager == null) return
    userManager
      .signinSilentCallback()
      .then(() => {})
      .catch(err => {
        console.log(err)
      })
  },
  createSigninRequest: () => {
    if (!isInUserEnvironment()) return null
    if (userManager == null) return null
    return userManager.createSigninRequest()
  },
  signout: () => {
    userManager.signoutRedirect({
      id_token_hint: localStorage.getItem('id_token')
    })
    userManager.clearStaleState()
  },
  signoutRedirectCallback: () => {
    if (!isInUserEnvironment()) return
    if (userManager == null) return
    userManager.signoutRedirectCallback().then(() => {
      localStorage.clear()
      window.location.replace('/')
    })
    userManager.clearStaleState()
  },
  setRedirectAfterLoginUri(url) {
    localStorage.setItem('redirectUri', url)
  }
}

if (isInUserEnvironment()) {
  if (userManager === null) userManager = new UserManager(getConfig())
}

function checkIfAccessTokenIsExpired(expiresAt) {
  var expiresAtTime = expiresAt * 1000 // ExpiresAt * 1000 gives the expiry time of the token

  if (expiresAtTime < new Date().getTime()) {
    return true
  }

  return false
}
